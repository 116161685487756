import React from 'react';
import ProductItem from './ProductItem';
import Loading from '../common/Loading/Loading';
import NoticeMessage from '../common/NoticeMessage/NoticeMessage';
import { Product } from '../../store/types/productTypes';

interface ProductListProps {
    products: Product[],
    loading: boolean,
    error: string | null
}

const ProductList: React.FC<ProductListProps> = ({ products, loading, error }) => {
    if (error) return <p>Error: {error}</p>;

    return (
        <>
            {!products.length && !loading && <NoticeMessage message={'Упс, тут нічого немає...'} />}
            <ul className='product-list'>
                {loading && <Loading />}
                {products.map((product) => (
                    <li key={product.id}>
                        <ProductItem product={product} />
                    </li>
                ))}
            </ul>
        </>
    );
};

export default ProductList;
