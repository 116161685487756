import React from "react";
import logoSrc from '../../assets/images/logo.webp';
import './Header.css';
import { Link } from "react-router-dom";
import Menu from "../Nav/Menu";
import BeerMug from "./BeerMug";

export const Header = () => {
    return (
        <header className="header">
            <div className="header-content">
                <BeerMug/>
                <div className="logo">
                    <Link to="/">
                        <img src={logoSrc} alt="ЖивПив" width="500" height="125" />
                    </Link>
                </div>
                <Menu />
            </div>
        </header>
    );
};
