import { configureStore } from '@reduxjs/toolkit';
import productReducer from './slices/productSlicer';
import sliderReducer from './slices/sliderSlicer';
import categoryReducer from './slices/categorySlicer';
import shopsReduser from './slices/shopsSlicer';
import blogReducer from './slices/blogSlicer';

export const store = configureStore({
    reducer: {
        products: productReducer,
        categories: categoryReducer,
        sliders: sliderReducer,
        shops: shopsReduser,
        blog: blogReducer
    },
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch