import React, {useEffect} from "react"
import MetaTags from "../../components/common/Seo/MetaTags";
import he from "he";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../store/store";
import {selectPosts, selectError, selectLoading, fetchPostById} from "../../store/slices/blogSlicer";
import {PostType} from "../../store/types/blogTypes";
import Loading from "../../components/common/Loading/Loading";
import NoticeMessage from "../../components/common/NoticeMessage/NoticeMessage";
import ImageWrapper from "../../components/common/ImageWrapper/ImageWrapper";

const Post = () => {
    const {postId} = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const posts = useSelector(selectPosts);
    const error = useSelector(selectError);
    const loading = useSelector(selectLoading);
    const post: PostType | undefined = postId ? posts?.find((item: any) => item.id === Number(postId)) : undefined;

    useEffect(() => {
        if (postId && !post) {
            dispatch(fetchPostById(Number(postId)));
        }
    }, [dispatch, post, postId]);

    if (error) return <NoticeMessage message={error}/>

    if (!post) return null;

    const mediaDetails = post._embedded?.['wp:featuredmedia']?.[0]?.media_details || null;
    const title = he.decode(post.title?.rendered || "");
    const content = he.decode(post.content?.rendered || "");

    return (
        <>
            {loading && <Loading />}
            <MetaTags title={`${title}`}
                      description={`${title}`}/>
            <div className="page-content"></div>
            <div className="blog-wrapper">
                <div className="blog-container">
                    <div className="content-wrapper">
                        <h1>{title}</h1>
                        {mediaDetails &&
                            <ImageWrapper mediaDetails={mediaDetails} altText={title}/>
                        }
                    </div>
                    <div className="content-wrapper" dangerouslySetInnerHTML={{__html: content}}></div>
                </div>
            </div>
        </>
    )
}

export default Post;