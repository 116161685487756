import React from 'react';
import ProductImage from './ProductImage';
import he from 'he';
import { NavLink } from 'react-router-dom';

interface MediaDetails {
    sizes: {
        medium_large?: {
            source_url?: string;
        };
        full?: {
            source_url?: string;
        };
    };
}

interface Product {
    id: number;
    title?: {
        rendered?: string;
    };
    _embedded?: {
        'wp:featuredmedia'?: [
            {
                media_details: MediaDetails;
            }
        ];
    };
}

interface ProductProps {
    product: Product
}

const ProductItem: React.FC<ProductProps> = ({ product }) => {
    const mediaDetails = product._embedded?.['wp:featuredmedia']?.[0]?.media_details || null;
    const title = he.decode(product.title?.rendered || '');

    return (
        <NavLink to={`/product/${product.id}`}>
            <div className='product-list-item'>
                {mediaDetails &&
                    <ProductImage mediaDetails={mediaDetails} altText={title} />
                }
                <div className='product-item-title'>
                    <div className="text">
                        {title}
                    </div>
                </div>
            </div>
        </NavLink>
    );
};

export default ProductItem;
