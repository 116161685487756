import React from "react";
import { CategoryList } from "../../components/Category/CategoryList";
import SliderProducts from "../../components/Slider/Slider";
import { Shops } from "../../components/Shops/Shops";
import { MainScreen } from "../../components/common/MainScreen/MainScreen";
import MetaTags from "../../components/common/Seo/MetaTags";
import "./Home.css";

export const Home = () => {
    return (
        <>
            <MetaTags title={"Магазин розливного пива і снеків"}
                      description={"Широкий асортимент розливного пива, вина та смачних снеків."}/>
            <MainScreen />
            <div className="page-content homepage">
                <SliderProducts sliderId={29} />
                <CategoryList />
            </div>
            <Shops />
        </>
    );
};
