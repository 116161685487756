import React from 'react';

interface ProductImageProps {
    mediaDetails: {
        sizes: {
            medium?: {
                source_url?: string
            },
            large?: {
                source_url?: string
            }
            full?: {
                source_url?: string
            }
        }
    },
    altText: string,
    largeImg?: boolean
}

const ProductImage: React.FC<ProductImageProps> = ({mediaDetails, altText, largeImg = false}) => {
    const {sizes} = mediaDetails;

    return (
        <div className="product-image-wrapper">
            <picture>
                {largeImg && sizes?.large && <source srcSet={sizes.large.source_url} media="(min-width: 300px)"/>}
                {!largeImg && sizes?.large && <source srcSet={sizes.large.source_url} media="(min-width: 520px)"/>}
                {!largeImg && sizes?.medium && <source srcSet={sizes.medium.source_url} media="(min-width: 300px)"/>}
                <img src={sizes?.full?.source_url} width="640" height="640" alt={`Зображення продукту ${altText}` || 'Product image'}
                     loading="lazy"/>
            </picture>
        </div>
    );
};

export default ProductImage;
