import React from 'react';
import { NavLink } from 'react-router-dom';

const NavLinks = () => {
  return (
      <ul>
          <li><NavLink to="/">Головна</NavLink></li>
          <li><NavLink to="/products/page/1">Каталог</NavLink></li>
          <li><NavLink to="/shops">Магазини</NavLink></li>
          <li><NavLink to="/blog">Блог</NavLink></li>
      </ul>
  );
}

export default NavLinks;
