import React from "react";
import {Shops} from "../../components/Shops/Shops";
import MetaTags from "../../components/common/Seo/MetaTags";

export const ShopsPage: React.FC = () => {
    return (
        <>
            <MetaTags title={"Адреси наших магазинів"}
                      description={"м. Хмельницький, вул. Подільська, 78, вул. Староконстянтинівське шосе, 26/9А, вул. Попова, 3, Графік роботи - щодня: 10.00-22.00"}
                      url_src={"https://api.zhyvpyv.km.ua/wp-content/uploads/2024/10/podilska-768x432.jpg"}/>
            <div className="page-content"></div>
            <Shops/>
        </>
    )
}