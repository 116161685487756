import React from "react";
import ImageWrapper from "../common/ImageWrapper/ImageWrapper";
import he from 'he';
import {RouteBtn} from "../common/RouteBtn/RouteBtn";
import {Shop as ShopType} from '../../store/types/shopTypes';

interface ShopProps {
    shop: ShopType
}

export const Shop: React.FC<ShopProps> = ({shop}) => {
    const mediaDetails = shop._embedded?.['wp:featuredmedia']?.[0]?.media_details || null;
    const title = he.decode(shop.title?.rendered || '');

    return (
        <div className="shop-item-container">
            {mediaDetails &&
                <ImageWrapper mediaDetails={mediaDetails} altText={title}/>
            }
            <div className="shop-desc">
                <h3 className='shop-item-title'>
                    <div className="text">
                        {title}
                    </div>
                </h3>
                <address>
                    {shop.excerpt?.rendered &&
                        <div className="shop-excerpt" dangerouslySetInnerHTML={{__html: shop.excerpt.rendered}}></div>}
                    {shop.content?.rendered &&
                        <div className="shop-content" dangerouslySetInnerHTML={{__html: shop.content.rendered}}></div>}
                </address>

                <RouteBtn slug={shop.slug}/>
            </div>
        </div>
    );
};
