import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Shop } from '../types/shopTypes';
import { RootState } from '../store';
import axiosInstance from '../../api/axiosInstance';

interface Coords {
  x: number;
  y: number;
}

interface ShopState {
  shops: Shop[];
  loading: boolean;
  error: string | null;
  coords: Record<string, Coords>;
}

const initialState: ShopState = {
  shops: [],
  loading: false,
  error: null,
  coords: {
    "1-podilska": {x: 49.42564248190439, y: 26.98936975066536},
    "2-starokonst": {x: 49.42128022588514, y: 27.002291714158495},
    "3-popova": {x: 49.40089565859574, y: 27.054264575828498}
}
};

export const fetchShops = createAsyncThunk<Shop[]>('shops/fetch', async () => {
  const response = await axiosInstance.get(`/shops?orderby=slug&order=asc&per_page=100&_embed`);
  return response.data;
});

const shopSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShops.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShops.fulfilled, (state, action: PayloadAction<Shop[]>) => {
        state.loading = false;
        state.shops = action.payload;
      })
      .addCase(fetchShops.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export const selectShops = (state: RootState) => state.shops.shops;
export const selectLoading = (state: RootState) => state.shops.loading;
export const selectError = (state: RootState) => state.shops.error;
export const selectCoords = (state: RootState) => state.shops.coords;

export default shopSlice.reducer;