import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSliderProducts, selectSliders, selectError, selectLoading } from "../../store/slices/sliderSlicer";
import Loading from "../common/Loading/Loading";
import SliderProductItem from "./SliderProductItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import SwiperCore from "swiper";
import { Autoplay } from "swiper/modules";
import { AppDispatch } from "../../store/store";

SwiperCore.use([Autoplay]);

interface SliderProductsProps {
    sliderId: number;
}

const SliderProducts: React.FC<SliderProductsProps> = ({sliderId}) => {
    const dispatch = useDispatch<AppDispatch>();
    const sliders = useSelector(selectSliders);
    const error = useSelector(selectError);
    const loading = useSelector(selectLoading);
    const sliderData = sliders[sliderId];

    useEffect(() => {
        if (!sliderData) {
            dispatch(fetchSliderProducts({sliderId}));
        }
    }, [dispatch, sliderId, sliderData]);

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (loading && !sliderData) {
        return <Loading />;
    }

    return (
        <div className="slider-products">
            {sliderData && (
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        640: {
                            spaceBetween: 10,
                            slidesPerView: 2,
                        },
                        768: {
                            spaceBetween: 20,
                            slidesPerView: 3,
                        },
                        1024: {
                            spaceBetween: 20,
                            slidesPerView: 4,
                        },
                    }}
                >
                    {sliderData.map((product) => (
                        <SwiperSlide key={product.id}>
                            <SliderProductItem product={product} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>)
}

export default SliderProducts;
