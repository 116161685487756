import React from 'react';

interface ImageWrapperProps {
  mediaDetails: {
    sizes: {
      medium_large?: {
        source_url?: string,
      },
      full?: {
        source_url?: string,
        width?: number,
        height?: number
      }
    }
  },
  altText: string
}

const ImageWrapper: React.FC<ImageWrapperProps> = ({ mediaDetails, altText }) => {
  const { sizes } = mediaDetails;

  return (
    <div className="image-wrapper">
      <picture>
        {sizes?.medium_large?.source_url && (
          <source srcSet={sizes.medium_large.source_url} media="(min-width: 300px)" />
        )}
        <img src={sizes?.full?.source_url || ''} alt={altText || 'Image'} loading="lazy"
             width={sizes?.full?.width} height={sizes?.full?.height} />
      </picture>
    </div>
  );
};

export default ImageWrapper;
