import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import { ShopsPage } from './pages/Shops/ShopsPage';
import { Header } from './components/Header/Header';
import { Home } from './pages/Home/Home';
import { Products } from './pages/Products/Products';
import { Product } from './pages/Products/Product';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Footer from './components/common/Footer/Footer';
import Blog from "./pages/Blog/Blog";
import Post from "./pages/Blog/Post";
import ReactGA from "react-ga4";
import './styles/variables.css';
import './styles/main.css';


function App() {
  ReactGA.initialize("G-BP6MMR5Z62");
  return (
    <Provider store={store}>
      <div className="page-wrapper">
        <Header />
        <main className='main'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products/:categoryId/page/:page" element={<Products />} />
            <Route path="/products/page/:page" element={<Products />} />
            <Route path="/product/:productId" element={<Product />} />
            <Route path="/products" element={<Navigate to="/products/page/1" replace />} />
            <Route path="/shops" element={<ShopsPage />} />
            <Route path="/blog/:page" element={<Blog />} />
            <Route path="/blog/" element={<Navigate to="/blog/1" replace />} />
            <Route path="/post/:postId" element={<Post />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </Provider>
  );
}

export default App;
