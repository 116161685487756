import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {BlogState} from '../types/blogTypes';
import {RootState} from '../store';
import axiosInstance from '../../api/axiosInstance';

const initialState: BlogState = {
    loading: false,
    posts: [],
    error: null,
    totalPages: 0,
    currentPage: 1,
};

export const fetchPosts = createAsyncThunk(
    'blog/fetchPosts',
    async ({page = 1, limit = 4}: { page?: number; limit?: number }, {rejectWithValue}) => {
        try {
            const response = await axiosInstance.get(`/posts?page=${page}&per_page=${limit}&_embed`);
            const totalRecords = response.headers['x-wp-total'];
            const totalPages = parseInt(response.headers['x-wp-totalpages'], 10);
            return {data: response.data, totalRecords, totalPages};
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchPostById = createAsyncThunk(
    'blog/fetchPostById',
    async (postId: number, {rejectWithValue}) => {
        try {
            const response = await axiosInstance.get(`/posts/${postId}?_embed`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPosts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPosts.fulfilled, (state, action) => {
                state.loading = false;
                state.posts = action.payload.data;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(fetchPosts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchPostById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPostById.fulfilled, (state, action) => {
                state.loading = false;
                state.posts = [action.payload];
            })
            .addCase(fetchPostById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
    },
});

export const {setCurrentPage} = blogSlice.actions;

// Селектори
export const selectPosts = (state: RootState) => state.blog.posts;
export const selectLoading = (state: RootState) => state.blog.loading;
export const selectError = (state: RootState) => state.blog.error;
export const selectTotalPages = (state: RootState) => state.blog.totalPages;
export const selectCurrentPage = (state: RootState) => state.blog.currentPage;

export default blogSlice.reducer;