import React, {useEffect, forwardRef} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {fetchShops, selectShops, selectLoading} from '../../store/slices/shopsSlicer';
import Loading from "../common/Loading/Loading";
import {Shop} from "./Shop";
import {AppDispatch} from '../../store/store';
import './Shops.css';

interface ShopsProps {
}

export const Shops = forwardRef<HTMLDivElement, ShopsProps>((props, ref) => {
    const dispatch = useDispatch<AppDispatch>();
    const shops = useSelector(selectShops);
    const loading = useSelector(selectLoading);

    useEffect(() => {
        if (shops.length === 0) {
            dispatch(fetchShops());
        }
    }, [dispatch, shops]);

    if (loading) {
        return <Loading/>;
    }

    return (
        <div className="shops-wrapper" ref={ref}>
            {shops && <h2>Наші магазини:</h2>}
            <div className="shops-container">
                {shops.map((shop) => (
                    <div key={shop.id} className="shop-item-wrapper">
                        <Shop shop={shop}/>
                    </div>
                ))}
            </div>
        </div>
    );
});
