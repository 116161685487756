import React from 'react';
import he from 'he';
import { NavLink } from 'react-router-dom/dist';
import './Slider.css';
import ProductImage from '../Products/ProductImage';
import { Product } from '../../store/types/productTypes';

interface SliderProductItemProps {
    product: Product
}

const SliderProductItem: React.FC<SliderProductItemProps> = ({ product }) => {
    const mediaDetails = product._embedded?.['wp:featuredmedia']?.[0]?.media_details || null;
    const title = he.decode(product.title?.rendered || '');

    return (
        <NavLink to={`/product/${product.id}`}>
            <div className='product-slider-item'>
                {mediaDetails &&
                    <ProductImage mediaDetails={mediaDetails} altText={title} largeImg={true} />
                }
                <div className='product-item-title'>{title}</div>
            </div>
        </NavLink>
    );
};

export default SliderProductItem;
