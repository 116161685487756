import React from 'react';
import {PostType} from '../../store/types/blogTypes';
import {NavLink} from "react-router-dom";
import he from "he";
import ImageWrapper from "../common/ImageWrapper/ImageWrapper";

interface PostItemProps {
    post: PostType;
}

const PostItem: React.FC<PostItemProps> = ({post}) => {
    const link = `/post/${post.id}`;
    const title = he.decode(post.title?.rendered || "");
    const excerpt = he.decode(post.excerpt?.rendered || "");
    const mediaDetails = post._embedded?.['wp:featuredmedia']?.[0]?.media_details || null;

    return (
        <>
            <NavLink to={link}>
                {mediaDetails &&
                    <ImageWrapper mediaDetails={mediaDetails} altText={title}/>
                }
            </NavLink>
            <NavLink to={link}>
                <h2>{title}</h2>
            </NavLink>
            <div className="short-post-desc" dangerouslySetInnerHTML={{__html: excerpt}}></div>
            <NavLink to={link} className={"btn btn-primary"}>
                <span>Переглянути</span>
            </NavLink>
        </>
    );
};

export default PostItem;
