import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";

interface SensorOptions {
    frequency?: number;
}

interface GravitySensor extends EventTarget {
    x: number;
    y: number;
    z: number;
    start: () => void;
    stop: () => void;
}

declare global {
    interface Window {
        GravitySensor?: {
            new (options?: SensorOptions): GravitySensor;
            prototype: GravitySensor;
        };
    }
}

const BeerMug: React.FC = () => {
    const [rotation, setRotation] = useState(0);
    const angleLimit = 45;

    useEffect(() => {
        if (window.GravitySensor) {
            const sensor = new window.GravitySensor({ frequency: 15 });

            sensor.addEventListener('reading', () => {
                let angle = Math.round(sensor.x * 9);
                if (angle > angleLimit) angle = angleLimit;
                if (angle < -angleLimit) angle = -angleLimit;
                setRotation(Math.round(angle));
            });

            sensor.start();
        } else {
            console.log('GravitySensor не підтримується.');
        }
    }, []);

    return (
        <div className="beer-icon-wrapper">
            <NavLink to="/products/22/page/1" title={'Каталог товарів'}>
                <div className="beer-icon"
                     style={{
                         transform: `rotate(${rotation}deg)`
                     }}></div>
            </NavLink>
        </div>
    );
};

export default BeerMug;