import React, { useState } from 'react';
import "./Menu.css";
import Nav from './Nav';
import MenuBtn from './MenuBtn';

const Menu = () => {
    const [menuOpened, setMenuOpened] = useState(false);
    return (
        <>
            <Nav menuOpened={menuOpened} setMenuOpened={setMenuOpened} />
            <MenuBtn menuOpened={menuOpened} setMenuOpened={setMenuOpened} />
        </>
    );
}

export default Menu;