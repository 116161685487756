import React from "react";

export const FooterAddresses: React.FC = () => {
    return (
        <div className="footer-addresses">
            <div className="shop-address-city">
                <span className="text">м. Хмельницький</span>
            </div>
            <address className="shop-address-item">
                вул. Подільська, 78
            </address>
            <address className="shop-address-item">
                вул. Староконстянтинівське шосе, 26/9А
            </address>
            <address className="shop-address-item">
                вул. Попова, 3
            </address>
        </div>
    );
}
