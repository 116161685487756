import React from "react";
import MetaTags from "../../components/common/Seo/MetaTags";
import PostList from "../../components/Post/PostList";
import "./Blog.css";
import {PostPagination} from "../../components/Post/PostPagination";
import ReactGA from "react-ga4";

const Blog: React.FC = () => {
    const title = "ЖивПив Блог – Поради, новинки та цікаві факти про пиво та закуски";

    const handleOnBlogPage = () => {
        ReactGA.event({
            category: "User",
            action: "Blog event",
            label: "Blog event",
        });
    };

    handleOnBlogPage();

    return (
        <>
            <MetaTags title={`${title}`}
                      description={`${title}`}/>
            <div className="page-content"></div>
            <div className="blog-wrapper">
                <div className="blog-container">
                    <h1>{title}</h1>
                    <PostList/>
                    <PostPagination/>
                </div>
            </div>
        </>
    )
}

export default Blog;