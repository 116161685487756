import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '../types/productTypes';
import { RootState } from '../store';
import axiosInstance from '../../api/axiosInstance';

interface SlidersState {
    loading: boolean;
    sliders: Record<string, Product[]>;
    error: string | null;
}

const initialState: SlidersState = {
    loading: false,
    sliders: {},
    error: null
};

interface FetchSliderProductsArgs {
    sliderId: number;
    limit?: number;
}

export const fetchSliderProducts = createAsyncThunk<Product[], FetchSliderProductsArgs>(
    'slider/fetch',
    async ({ sliderId, limit = 10 }) => {
        const response = await axiosInstance.get(`/products?sliders=${sliderId}&per_page=${limit}&_embed`);
        return response.data;
    }
);

const sliderSlice = createSlice({
    name: 'slider',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSliderProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSliderProducts.fulfilled, (state, action: PayloadAction<Product[], string,  { arg: FetchSliderProductsArgs }>) => {
                const sliderId = action.meta.arg.sliderId;
                state.loading = false;
                state.sliders[sliderId] = action.payload;
            })
            .addCase(fetchSliderProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
});

export const selectSliders = (state: RootState) => state.sliders.sliders;
export const selectLoading = (state: RootState) => state.sliders.loading;
export const selectError = (state: RootState) => state.sliders.error;

export default sliderSlice.reducer;
