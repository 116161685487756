import React from "react";
import { useSelector } from 'react-redux';
import { selectCoords } from "../../../store/slices/shopsSlicer";

interface RouteBtnProps {
    slug: string
}

export const RouteBtn: React.FC<RouteBtnProps> = ({ slug }) => {
    const coords = useSelector(selectCoords);

    if (!coords[slug]) return null;
    const latitude = coords[slug].x;
    const longitude = coords[slug].y;
    const label = "ЖивПив"
    let url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    const appleMapsAppUrl = `maps://?daddr=${latitude},${longitude}&dirflg=d`;
    const googleMapsAppUrl = `geo:${latitude},${longitude}?q=${latitude},${longitude}(${label})`;
    const userAgent = navigator.userAgent;
    
    if (/iPhone|iPad|iPod/.test(userAgent)) {
        url = appleMapsAppUrl;
    }

    if (/Android/.test(userAgent)) {
        url = googleMapsAppUrl;
    }

    return (
        <div>
            <a href={url} className="btn btn-secondary route" target="_blank" rel="noreferrer">
                <span className="text">Прокласти маршрут</span>
                <span className="ic-route"></span>
            </a>
        </div>
    );
};
