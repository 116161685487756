import React from "react";
import "./SocialLinks.css"

export const SocialLink = () => {
    return (
        <div className="social-links-wrapper">
            <a href="https://t.me/ZhyvPyv_khm" title="Телеграм канал магазину ЖивПив"><span className="soc-ic telegram"></span></a>
        </div>
    )
}
