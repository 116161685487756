import React from "react";
import NavLinks from "../../Nav/NavLinks";
import {FooterAddresses} from "./FooterAddresses";
import {SocialLink} from "./SocialLinks";
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="top-level">
                    <FooterAddresses/>
                    <NavLinks/>
                </div>
                <div className="middle-level">
                    <SocialLink/>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
