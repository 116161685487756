import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchCategories, selectCategories } from '../../../store/slices/categorySlicer';
import { AppDispatch } from '../../../store/store';

import './BreadCrumbs.css';

interface BreadCrumbsProps {
    title: string,
    product?: {
        categories: number[]
    }
}

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ title, product }) => {
    const dispatch = useDispatch<AppDispatch>();
    const categories = useSelector(selectCategories);

    useEffect(() => {
        if (categories.length === 0) {
            dispatch(fetchCategories());
        }
    }, [dispatch, categories]);

    let categoryName = 'Усі товари';
    let categoryId: number | null = null;

    if (product && product.categories && product.categories.length > 0) {
        categoryId = product.categories[0];
        categoryName = categories.find(category => category.id === categoryId)?.name || 'Усі товари';
    }

    return (
        <div className="breadcrumbs-wrapper">
            <div className="breadcrumbs">
                <NavLink to={'/'}>Головна</NavLink>
                {categoryId && <NavLink to={`/products/${categoryId}/page/1`}>{categoryName}</NavLink>}
                <span>{title}</span>
            </div>
        </div>
    );
};
