import React, {useEffect, useRef, useState} from "react";
import ProductList from "../../components/Products/ProductList";
import {useDispatch, useSelector} from 'react-redux';
import {Pagination} from "../../components/Pagination/Pagination";
import {useParams} from 'react-router-dom';
import {CategoryList} from "../../components/Category/CategoryList";
import {BreadCrumbs} from "../../components/common/BreadCrumbs/BreadCrumbs";
import {
    fetchProducts,
    selectTotalPages,
    selectProducts,
    selectLoading,
    selectError
} from '../../store/slices/productSlicer';
import {AppDispatch} from '../../store/store';
import {selectCategories} from '../../store/slices/categorySlicer';

import './Products.css';
import MetaTags from "../../components/common/Seo/MetaTags";

interface RouteParams {
    categoryId?: string;
    page?: string;

    [key: string]: string | undefined;
}

export const Products = () => {
    const dispatch = useDispatch<AppDispatch>();
    const products = useSelector(selectProducts);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const totalPages = useSelector(selectTotalPages)
    const {categoryId, page} = useParams<RouteParams>();
    const currentPage = page ? parseInt(page, 10) : 1;
    const categories = useSelector(selectCategories);
    const limit = 12;

    const prevCategoryId = useRef<string | undefined>(undefined);
    const prevPage = useRef<number | undefined>(undefined);

    const [currentCategory, setCurrentCategory] = useState('Усі товари');

    useEffect(() => {
        if (categoryId !== prevCategoryId.current || currentPage !== prevPage.current) {
            prevCategoryId.current = categoryId;
            prevPage.current = currentPage;
            dispatch(fetchProducts({page: currentPage, categoryId: categoryId ? parseInt(categoryId) : null, limit}));
        }
        const categoryName = categories.find(category => category.id === Number(categoryId))?.name || 'Усі товари';
        setCurrentCategory(categoryName);
    }, [dispatch, categoryId, currentPage, categories]);

    return (
        <>
            <MetaTags title={`${currentCategory} | Магазин розливного пива і снеків`}
                      description={`${currentCategory} Каталог товарів магазину розливного пива.`}/>
            <div className="page-content products-page">
                {currentCategory && <BreadCrumbs title={currentCategory}/>}
                <h1>{currentCategory}</h1>
                <ProductList products={products} loading={loading} error={error}/>
                <Pagination currentPage={currentPage} totalPages={totalPages} categoryId={categoryId}/>
                <CategoryList isCatalog={true}/>
            </div>
        </>
    );
};
