import React, {useEffect} from 'react';
import Loading from '../common/Loading/Loading';
import NoticeMessage from '../common/NoticeMessage/NoticeMessage';
import {PostType} from '../../store/types/blogTypes';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {fetchPosts, selectError, selectLoading, selectPosts} from "../../store/slices/blogSlicer";
import {useParams} from "react-router-dom";
import PostItem from "./PostItem";

interface RouteParams {
    page?: string;
    [key: string]: string | undefined;
}

const PostList: React.FC = () => {
    const dispatch = useAppDispatch();
    const posts = useAppSelector(selectPosts);
    const loading = useAppSelector(selectLoading);
    const error = useAppSelector(selectError);
    const {page} = useParams<RouteParams>();
    const currentPage = page ? Number(page) : 1;

    useEffect(() => {
        dispatch(fetchPosts({page: currentPage}));
    }, [dispatch, currentPage]);

    if (error) return <p>Error: {error}</p>;

    return (
        <>
            {!posts.length && !loading && <NoticeMessage message={'Упс, тут нічого немає...'}/>}
            <ul className='post-list'>
                {loading && <Loading/>}
                {posts.map((post: PostType) => (
                    <li key={post.id}>
                        <PostItem post={post}/>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default PostList;
