import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '../types/productTypes';
import { RootState } from '../store';
import axiosInstance from '../../api/axiosInstance';

interface ProductState {
    loading: boolean;
    products: Product[];
    error: string | null;
    totalPages: number;
    currentPage: number;
}

const initialState: ProductState = {
    loading: false,
    products: [],
    error: null,
    totalPages: 0,
    currentPage: 1,
};

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async ({ page = 1, categoryId = null, limit = 10 }: { page?: number; categoryId?: number | null; limit?: number }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/products?${categoryId !== null ? `categories=${categoryId}&` : ''}page=${page}&per_page=${limit}&_embed`);
            const totalRecords = response.headers['x-wp-total'];
            const totalPages = parseInt(response.headers['x-wp-totalpages'], 10);
            return { data: response.data, totalRecords, totalPages };
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchProductById = createAsyncThunk(
    'products/fetchProductById',
    async (productId: number, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/products/${productId}?_embed`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.data;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchProductById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProductById.fulfilled, (state, action) => {
                state.loading = false;
                state.products = [action.payload];
            })
            .addCase(fetchProductById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { setCurrentPage } = productSlice.actions;

// Селектори
export const selectProducts = (state: RootState) => state.products.products;
export const selectLoading = (state: RootState) => state.products.loading;
export const selectError = (state: RootState) => state.products.error;
export const selectTotalPages = (state: RootState) => state.products.totalPages;
export const selectCurrentPage = (state: RootState) => state.products.currentPage;

export default productSlice.reducer;