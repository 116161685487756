import React from 'react';
import NavLinks from './NavLinks';

interface NavProps {
  menuOpened: boolean;
  setMenuOpened: (opened: boolean) => void;
}

const Nav: React.FC<NavProps> = ({ menuOpened, setMenuOpened }) => {
  return (
    <nav className={`nav${menuOpened ? ' active' : ''}`} onClick={() => setMenuOpened(false)}>
      <NavLinks />
    </nav>
  );
}

export default Nav;
