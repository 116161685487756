import React from 'react';

interface MenuBtnProps {
    menuOpened: boolean,
    setMenuOpened: (opened: boolean) => void;
}

const MenuBtn: React.FC<MenuBtnProps> = ({menuOpened, setMenuOpened}) => {
    return (
        <div className={`menu-btn${menuOpened ? ' active' : ''}`} onClick={()=>setMenuOpened(!menuOpened)}>
            <div className="bar upper-bar"></div>
            <div className="bar middle-bar"></div>
            <div className="bar lower-bar"></div>
        </div>
    );
}

export default MenuBtn;