import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, selectCategories, selectLoading, selectError } from '../../store/slices/categorySlicer';
import { AppDispatch } from '../../store/store';
import { NavLink, useParams } from 'react-router-dom';
import Loading from "../common/Loading/Loading";
import { Category } from '../../store/types/categoryTypes';
import './CategoryList.css';


interface CategoryListProps {
    isCatalog?: boolean;
}

export const CategoryList: React.FC<CategoryListProps> = ({ isCatalog = false }) => {
    const dispatch = useDispatch<AppDispatch>();
    const categories = useSelector(selectCategories);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);

    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    const { categoryId } = useParams<{ categoryId?: string }>();

    const isActive = function (category?: Category): boolean {
        if (!category && !categoryId && isCatalog) {
            return true;
        }
        return categoryId ? parseInt(categoryId, 10) === category?.id : false;
    };

    if (error) return <p>Error: {error}</p>;

    return (
        <div className="category-list">
            {loading && <Loading />}
            <ul>
                {categories.length > 0 && <li>
                    <NavLink to="/products/page/1"
                        className={isActive() ? "active" : ""}
                    >Усі товари</NavLink>
                </li>}
                {categories.map((category) => (
                    <li key={category.id}>
                        <NavLink to={`/products/${category.id}/page/1`}
                            className={isActive(category) ? "active" : ""}
                        >{category.name}</NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};
