import React from "react";
import { NavLink } from "react-router-dom";
import '../Pagination/Pagination.css';
import {useAppSelector} from "../../store/hooks";
import {selectTotalPages, selectCurrentPage} from "../../store/slices/blogSlicer";

export const PostPagination: React.FC = () => {
    // const dispatch = useAppDispatch();

    const currentPage = useAppSelector(selectCurrentPage);
    const totalPages = useAppSelector(selectTotalPages);
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    const maxPagesToShow = 7;
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > maxPagesToShow) {
        startPage = Math.max(1, currentPage - 3);
        endPage = Math.min(totalPages, currentPage + 3);

        if (startPage === 1) {
            endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
        }
        else if (endPage === totalPages) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <div className="pagination-pages">
                {pageNumbers.map((number) => (
                    <NavLink
                        key={number}
                        to={`/blog/${number}`}
                    >
                        {number}
                    </NavLink>
                ))}
            </div>
        </div>
    );
};
