import React from "react";
import './ShowContent.css';

interface ShowContentProps {
    content?: string
}

export const ShowContent: React.FC<ShowContentProps> = ({ content }) => {
    if (!content) return null;

    return (
        <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: content }}></div>
    );
};
