import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import './MainScreen.css'

export const MainScreen = () => {
    const blockRef = useRef<HTMLDivElement | null>(null);

    const handleScroll = () => {
        const header = document.querySelector<HTMLElement>(".header");
        const headerHeight = header ? header.offsetHeight : 0;

        if (blockRef.current) {
            const blockHeight = blockRef.current.offsetHeight;
            window.scrollTo({
                top: blockHeight - headerHeight,
                behavior: "smooth",
            });
        }
    };
    
    return (
        <div className="main-screen" ref={blockRef}>
            <div className="main-screen-container">
                <div className="main-screen-slider-container">
                    <div className="main-screen-slider">
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            loop
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                        >
                            <SwiperSlide>
                                <div className="promo promo-1">Якщо набираєте розливних напоїв на <strong>200 грн</strong> - отримуєте пляшку Хмельницького!</div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="promo promo-2">Кожна <strong>11-та</strong> літра пива безкоштовна!</div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="promo promo-3">В день народження <strong>20% знижка!</strong></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="promo promo-4">Програма лояльності - <strong>3%</strong> накопичення від покупки!</div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className="next-screen-btn-wrapper">
                    <button className="next-screen-btn" onClick={handleScroll} aria-label="На наступний екран">
                        <div className="inner-btn">
                            <div className="ic-next"></div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};
