import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductById } from '../../store/slices/productSlicer';
import { useParams } from 'react-router-dom';
import ProductImage from "../../components/Products/ProductImage";
import Loading from "../../components/common/Loading/Loading";
import he from 'he';
import { ShowContent } from "../../components/common/ShowContent/ShowContent";
import { BreadCrumbs } from "../../components/common/BreadCrumbs/BreadCrumbs";
import { Shops } from "../../components/Shops/Shops";
import SliderProducts from "../../components/Slider/Slider";
import { AppDispatch } from '../../store/store';
import { selectProducts } from '../../store/slices/productSlicer';
import MetaTags from "../../components/common/Seo/MetaTags";


export const Product = () => {
    const { productId } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const products = useSelector(selectProducts);
    const product = productId ? products?.find((item: any) => item.id === Number(productId)) : undefined;
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const offset = -76;

    const scrollToShops = () => {
        if (scrollRef.current) {
            const elementPosition = scrollRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        if (productId && !product) {
            dispatch(fetchProductById(parseInt(productId, 10)));
        }
    }, [dispatch, product, productId]);

    if (!product) {
        return <Loading />;
    }

    const mediaDetails = product._embedded?.['wp:featuredmedia']?.[0]?.media_details || null;
    const title = he.decode(product.title?.rendered || "");

    return (
        <>
            <MetaTags title={`${title} | Магазин розливного пива і снеків`}
                      description={`${title} Каталог товарів магазину розливного пива.`}/>
            <div className="page-content pdp-page">
                <BreadCrumbs title={title} product={product} />
                <div className="pdp-main-wrapper">
                    <div className="pdp-main-container">
                        <div className="pdp-photo-wrapper">
                            {mediaDetails &&
                                <ProductImage mediaDetails={mediaDetails} altText={title} largeImg={true} />
                            }
                        </div>
                        <div className="pdp-main-info">
                            <h1>{title}</h1>
                            <h2>Де купити?</h2>
                            <button className="btn btn-primary choose-shop" onClick={scrollToShops}>Обирай магазин</button>
                        </div>
                    </div>
                </div>
                <div className="pdp-content">
                    <ShowContent content={product?.content?.rendered} />
                </div>
            </div>
            <Shops ref={scrollRef} />
            <SliderProducts sliderId={29} />
        </>
    );
};
