import React from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';

interface MetaTagsProps {
    title: string;
    description?: string | null;
    url_src?: string | null;
}

const MetaTags: React.FC<MetaTagsProps> = ({title, description = null, url_src = null}) => {
    const location = useLocation();
    const publicUrl = 'https://zhyvpyv.km.ua';
    const canonicalUrl = `${publicUrl}${location.pathname}`;
    const baseImgSrc = `${publicUrl}/main-bg.jpg`;

    return (
        <Helmet>
            <title>{`ЖивПив | ${title} | м. Хмельницький`}</title>
            <link rel="canonical" href={canonicalUrl}/>
            <meta property="og:title" content={`ЖивПив | ${title}`}/>
            <meta property="og:description" content={description ? description : title}/>
            <meta property="og:image" content={url_src ? url_src : baseImgSrc}/>
            <meta property="og:url" content={canonicalUrl}/>
        </Helmet>
    );
};

export default MetaTags;