import React from 'react';
import './NoticeMessage.css'

interface NoticeMessageProps {
    message: string
}

const NoticeMessage: React.FC<NoticeMessageProps> = ({message}) => {
    return (
        <div className='notice-message'>
            <div className='text'>{message}</div>
        </div>
    );
};

export default NoticeMessage;
